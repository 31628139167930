// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moskitiery-js": () => import("./../../../src/pages/moskitiery.js" /* webpackChunkName: "component---src-pages-moskitiery-js" */),
  "component---src-pages-plisy-js": () => import("./../../../src/pages/plisy.js" /* webpackChunkName: "component---src-pages-plisy-js" */),
  "component---src-pages-rolety-js": () => import("./../../../src/pages/rolety.js" /* webpackChunkName: "component---src-pages-rolety-js" */),
  "component---src-pages-zaluzje-js": () => import("./../../../src/pages/zaluzje.js" /* webpackChunkName: "component---src-pages-zaluzje-js" */)
}

